import React from "react";
import marble from '../assets/img/marble.svg'
import ScrollAnimation from "react-animate-on-scroll";

const Pricelist = () => {
    return (
        <div className="tpl__pricelist" id="cennik">
            <div className="shape" style={{ backgroundImage: `url(${marble})` }}></div>
            <div className="tpl">
                <div className="container">
                    <div className="row">
                        <div className="grid-xs-24">
                            <div className="content">
                                <div className="comp__heading">
                                    <h2 className="heading heading--beige">Cenník</h2>
                                    <div className="delimiter delimiter--beige"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row items">
                        <div className="grid-xs-24 item priceItems">
                            <ScrollAnimation animateIn="slideInLeft" duration={1.5} delay={100} animateOnce={true}>
                                <div className="priceItem">
                                    <div className="bg" />
                                    <div className="text">Vypracovanie ponuky</div>
                                    <span className="price">od 250 €/ponuka</span>
                                </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="slideInLeft" duration={1.5} delay={100} animateOnce={true}>
                                <div className="priceItem">
                                    <div className="bg" />
                                    <div className="text">Vypracovanie rozpočtu (PHZ do 100.000 €)</div>
                                    <span className="price">120 – 150 €</span>
                                </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="slideInLeft" duration={1.5} delay={100} animateOnce={true}>
                                <div className="priceItem">
                                    <div className="bg" />
                                    <div className="text">Vypracovanie rozpočtu (PHZ 100.001 - 300.000 €)</div>
                                    <span className="price">150 – 220 €</span>
                                </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="slideInLeft" duration={1.5} delay={100} animateOnce={true}>
                                <div className="priceItem">
                                    <div className="bg" />
                                    <div className="text">Vypracovanie rozpočtu (PHZ 300.001 - 500.000 €)</div>
                                    <span className="price">220 – 300 €</span>
                                </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="slideInLeft" duration={1.5} delay={100} animateOnce={true}>
                                <div className="priceItem">
                                    <div className="bg" />
                                    <div className="text">Vypracovanie rozpočtu (PHZ 500.001 - 700.000 €)</div>
                                    <span className="price">300 – 380 €</span>
                                </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="slideInLeft" duration={1.5} delay={100} animateOnce={true}>
                                <div className="priceItem">
                                    <div className="bg" />
                                    <div className="text">Vypracovanie rozpočtu (PHZ do 1.000.000 €)</div>
                                    <span className="price">380 – 450 €</span>
                                </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="slideInLeft" duration={1.5} delay={100} animateOnce={true}>
                                <div className="priceItem">
                                    <div className="bg" />
                                    <div className="text">Vypracovanie rozpočtu (PHZ do 2.000.000 €)</div>
                                    <span className="price">450 – 580 €</span>
                                </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="slideInLeft" duration={1.5} delay={100} animateOnce={true}>
                                <div className="priceItem">
                                    <div className="bg" />
                                    <div className="text">Vypracovanie rozpočtu (PHZ nad 2.000.000 €)</div>
                                    <span className="price">individuálna cena</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div >
            </div >
        </div >
    )
}


export default Pricelist;