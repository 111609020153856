import React from 'react';
import InlineSVG from "svg-inline-react";
import overlay from '../assets/img/softpink.svg'
import gold from '../assets/img/gold.svg'

const person = `<svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 624.93 624.16"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M42.92,172.9C-32.87,321.14,26.75,505.5,175,581.28c148.82,76.09,333.09,15.23,408.39-132.07,83.08-162.52-10.88-343.42-132.08-408.39-56.6-30.34-113.65-32.46-149.8-30.13" style="fill:none;stroke:#ddb7ab;stroke-miterlimit:10;stroke-width:20px"/></g><g id="Layer_4" data-name="Layer 4"><g id="ic_fluent_person_48_regular" data-name="ic fluent person 48 regular"><path d="M414.32,352a37,37,0,0,1,36.82,35.25l0,1.8v6.55c0,32.75-16.84,57.27-42.67,73.09-25.4,15.55-59.55,22.84-96.09,22.84s-70.69-7.29-96.09-22.84c-25.05-15.34-41.64-38.86-42.63-70.13l0-3v-6.55a37,37,0,0,1,35.07-37l1.78,0Zm0,21.8H210.51a15.2,15.2,0,0,0-15.12,14l0,1.25v6.55c0,23.94,11.8,41.93,32.26,54.46,20.9,12.8,50.71,19.67,84.82,19.67s63.92-6.87,84.81-19.67C417,438,428.64,420.82,429.45,398.15l.05-2.54v-6.55a15.22,15.22,0,0,0-13.93-15.2ZM312.42,142.7c47.89,0,86.72,39.05,86.72,87.21s-38.83,87.21-86.72,87.21-86.73-39-86.73-87.21S264.52,142.7,312.42,142.7Zm0,21.81a65.41,65.41,0,1,0,65,65.4A65.23,65.23,0,0,0,312.42,164.51Z" style="fill:#ddb7ab"/></g></g></g></svg>`
const time = `<svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 386.89 626.81"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M62.72,616.62c166.39,5.85,308.13-126.25,314-292.64,5.88-167-127.31-308.18-292.64-314" style="fill:none;stroke:#ddb7ab;stroke-miterlimit:10;stroke-width:20px"/></g><g id="Layer_5" data-name="Layer 5"><path d="M87,297.36a5.18,5.18,0,0,1-4-1.87l-19.44-23.4a5.18,5.18,0,0,1,4-8.49h38.9a5.18,5.18,0,0,1,4,8.49l-19.45,23.4A5.15,5.15,0,0,1,87,297.36ZM78.54,274,87,284.08,95.36,274Z" style="stroke:#ddb7ab;stroke-miterlimit:10;stroke-width:3px"/><path d="M119.87,406.34H54a5.18,5.18,0,0,1-5.18-5.18V367.87a5.14,5.14,0,0,1,1.2-3.31L83,325a5.18,5.18,0,0,1,4-1.87h0a5.18,5.18,0,0,1,4,1.87l32.92,39.61a5.14,5.14,0,0,1,1.2,3.31v33.29A5.18,5.18,0,0,1,119.87,406.34ZM59.21,396h55.48V369.74L87,336.37,59.21,369.74V396Z" style="stroke:#ddb7ab;stroke-miterlimit:10;stroke-width:3px"/><path d="M145.61,275.6a46,46,0,0,0,10.59-29.34v-44.9h11a5.18,5.18,0,0,0,5.18-5.18V161.13a5.18,5.18,0,0,0-5.18-5.18H6.68a5.18,5.18,0,0,0-5.18,5.18v35.05a5.18,5.18,0,0,0,5.18,5.18h11v44.9a46,46,0,0,0,10.6,29.34l22.94,27.59a12.8,12.8,0,0,1,0,16.36L28.3,347.14a46,46,0,0,0-10.61,29.34v44.9h-11a5.18,5.18,0,0,0-5.18,5.18v35.05a5.18,5.18,0,0,0,5.18,5.18H167.22a5.18,5.18,0,0,0,5.18-5.18v-35a5.19,5.19,0,0,0-5.18-5.19h-11v-44.9a46,46,0,0,0-10.59-29.33l-22.94-27.6a12.79,12.79,0,0,1,0-16.35ZM11.86,166.31H162V191H11.86ZM162,456.43H11.86V431.75H162v24.68ZM114.7,326.17l22.94,27.6a35.61,35.61,0,0,1,8.2,22.71v44.9H28.06v-44.9a35.56,35.56,0,0,1,8.21-22.71L59.2,326.18a23.17,23.17,0,0,0,0-29.61L36.26,269a35.63,35.63,0,0,1-8.2-22.72v-44.9H145.84v44.9a35.65,35.65,0,0,1-8.2,22.72L114.7,296.57A23.18,23.18,0,0,0,114.7,326.17Z" style="stroke:#ddb7ab;stroke-miterlimit:10;stroke-width:3px"/></g></g></svg>`
const bookcase = `<svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 624.37 611.61"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M105,78C-16.32,192-22.23,385.71,91.85,507c115.29,122.55,309.85,125.19,429,13.1C648.41,400.1,638.28,197,534,91.05,453.73,9.6,341.08,9.34,312.17,10.13" style="fill:none;stroke:#ddb7ab;stroke-miterlimit:10;stroke-width:20px"/></g><g id="Layer_2-2" data-name="Layer 2"><path d="M444.46,234.55h-85c-2.92-12.87-12.94-35.28-47.3-35.28s-44.37,22.41-47.29,35.28h-85a8.82,8.82,0,0,0-8.82,8.82V402.1a8.82,8.82,0,0,0,8.82,8.81H444.46a8.82,8.82,0,0,0,8.82-8.81V243.37A8.82,8.82,0,0,0,444.46,234.55ZM312.18,216.91c19.12,0,26.37,9.89,29.14,17.64H283C285.81,226.84,293.09,216.91,312.18,216.91Zm123.46,35.27v35.28H188.73V252.18ZM299,305.09h26.45v8.82H299ZM188.73,393.28V305.09h92.59v17.64a8.82,8.82,0,0,0,8.82,8.82h44.09a8.82,8.82,0,0,0,8.82-8.82V305.09h92.59v88.19Z"/></g></g></svg>`
const medal = `<svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 627 627"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M454,417.72l-56-99.18a101.82,101.82,0,1,0-168.89,0L173,417.73a14.51,14.51,0,0,0,16.51,21.1L214,432l6.82,24.51a14.51,14.51,0,0,0,26.59,3.25l54.77-96.93a102.6,102.6,0,0,0,22.57,0l54.76,96.93a14.5,14.5,0,0,0,26.6-3.25L413,432l24.53,6.81C449.91,442.28,460.32,429,454,417.72ZM235,451.35l-8.5-30.58a7.51,7.51,0,0,0-9.24-5.22l-30.59,8.5,52.47-92.85a102.08,102.08,0,0,0,15.58,13.59l-20.19,35.73a7.5,7.5,0,1,0,13.06,7.38l20-35.36a101.82,101.82,0,0,0,19.1,7.36Zm78.58-102.87h-.09a86.79,86.79,0,1,1,.09,0Zm96.18,67.08a7.49,7.49,0,0,0-9.23,5.21L392,451.35,340.35,359.9a101.94,101.94,0,0,0,19.09-7.36l20,35.36a7.5,7.5,0,1,0,13.06-7.38l-20.19-35.73a103,103,0,0,0,15.58-13.59l52.47,92.85Z"/><path d="M313.5,189.85a71.82,71.82,0,1,0,71.82,71.81A71.89,71.89,0,0,0,313.5,189.85Zm0,128.63a56.82,56.82,0,1,1,56.82-56.82A56.88,56.88,0,0,1,313.5,318.48Z"/><path d="M313.5,219.85a41.82,41.82,0,1,0,41.82,41.81A41.86,41.86,0,0,0,313.5,219.85Zm0,68.63a26.82,26.82,0,1,1,26.82-26.82A26.85,26.85,0,0,1,313.5,288.48Z"/><path d="M10,313.5C10,481.12,145.88,617,313.5,617S617,481.12,617,313.5,481.12,10,313.5,10" style="fill:none;stroke:#ddb7ab;stroke-miterlimit:10;stroke-width:20px"/></g></g></svg>`

const About = () => {
    return (
        <div className="tpl__about" id="o-nas">
            <div className="tpl">
                <div className="container">
                    <div className="row">
                        <div className="grid-xs-24">
                            <div className="content">
                                <div className="comp__heading">
                                    <h2 className="heading heading--pink">O nás</h2>
                                    <div className="delimiter delimiter--pink"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="grid-xs-24 descriptionWrapper">
                            <div className="description">
                                <p className="text text--pink">Zaoberáme sa komplexnou podporou a poradenstvom pri príprave ponúk pre uchádzačov a záujemcov v rámci verejného obstarávania na dodanie tovarov, poskytovanie služieb a uskutočňovanie stavebných prác.</p>
                                <p className="text text--pink">V rámci verejného obstarávania na uskutočňovanie stavebných prác poskytujeme vypracovanie rozpočtov a výkazov výmer pre pozemné stavby.</p>
                            </div>
                            <div className="verticalDelimiter"></div>
                            <div className="description">
                                <p className="text text--pink">Pri poskytovaní komplexnej podpory a poradenstva pri príprave ponúk v oblasti verejného obstarávania využívame dlhoročné praktické skúsenosti a vedomosti.</p>
                                <p className="text text--pink">Našim klientom účasťou vo verejnom obstarávaní pomáhame nájsť nové obchodné príležitosti, rozširovať svoje portfólio a rozvíjať ich podnikateľskú činnosť.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="grid-xs-22 grid-xs-offset-1 grid-m-16 grid-m-offset-4">
                            <div className="textWrapper">
                                <p className="text text--italic">Pri svojej činnosti preferujme osobitný prístup ku každému klientovi, lebo zo skúsenosti vieme, že iba taký prístup je základom úspechu.</p>
                                <div className="verticalDelimiter verticalDelimiter--smaller"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row iconsWrapper">
                        <div className="grid-xs-24 grid-s-12 grid-l-8 item">
                            <InlineSVG src={person} />
                            <span>Individuálny prístup</span>
                        </div>
                        <div className="grid-xs-24 grid-s-12 grid-l-8 item">
                            <InlineSVG src={time} style={{ marginLeft: "25px" }} />
                            <span>Rýchlosť a včasnosť</span>
                        </div>
                        <div className="grid-xs-24 grid-s-12 grid-l-8 item">
                            <InlineSVG src={medal} />
                            <span>Kvalita</span>
                        </div>
                        <div className="grid-xs-24 grid-s-12 grid-l-8 item">
                            <InlineSVG src={bookcase} />
                            <span>Dlhoročné skúsenosti</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="gold" style={{ backgroundImage: `url(${gold})` }}></div>
            <div className="overlay" style={{ backgroundImage: `url(${overlay})` }}></div>
        </div >
    );
}

export default About;