import React, { useState, useEffect } from 'react'
import {
    Routes,
    Route
} from "react-router-dom";
import { Navbar, Nav } from 'react-bootstrap'
import Home from './Home';
import InlineSVG from "svg-inline-react";
import Scrollspy from 'react-scrollspy';
import NotFoundPage from "./pages/NotFoundPage";
import wave from './assets/img/nav.svg'

const logo = `<svg class="logo" width="60px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 853.38 675.84"><title>Asset 3</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_2-2" data-name="Layer 2"><path d="M853.38,215.13S784.53,225.62,426.69,401C228.25,498.25,21.87,594,0,614c0,0,11.73,7.1,263.05-96.95C263.05,517.08,835.48,239.21,853.38,215.13Z" style="fill:#ddb7ab"/><path d="M713.41,0c-10.25,2.93-20.82,5.4-30.94,8.89-29.61,10.22-58.15,23.51-85.61,38.52-43.5,23.78-109.66,67.73-147.94,99.17-73,60-113.69,109.33-169.19,185.67-35.71,49.1-69,106.07-92.34,162.4-9.13,21.71-15.91,44.15-23.57,66.31a358.35,358.35,0,0,0-17,73.11c-1.12,9-1.84,18.53,1.47,27,11.24,28.81,54.86,8.23,72.72-1.47,36.2-19.65,69.22-48.11,98.29-77C360.09,542,399.51,499,437.73,456c11.17-12.56,28.42-31.51,39.58-44,0,0-92,42-97,47.8C344.42,501.68,303.9,544,262.6,580.61,252,590.05,240.92,599,229.85,607.89c-13.56,10.92-34,27.44-41.41,26.83-9.06,2.06-3.12-23.4-2.37-27.48,2.08-11.25,7.67-25.78,11.51-36.56,7.5-21.06,18.11-41.27,27.63-61.47C237.92,482.26,251,455.44,265,429.15c1.2-2.26,2.4-4.51,3.64-6.75,11.5-20.77,25.41-39.89,38.46-59.56,12.47-18.79,24.16-37.91,37.61-56.06a724.74,724.74,0,0,1,90-100.44c39.36-36.44,68.85-64.88,111-98C572.5,87.14,604.87,65,632.86,45.39c10.55-7.39,29.12-19.47,40.21-26A268.1,268.1,0,0,1,713.41,0Z" style="fill:#ddb7ab"/><circle cx="837.43" cy="288.51" r="15.95" style="fill:#ddb7ab"/></g></g></svg>`
const nav = `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1142.91 47.54"><defs><clipPath id="clip-path"><rect width="1142.91" height="47.54" style="fill:none"/></clipPath></defs><title>nav</title><g style="clip-path:url(#clip-path)"><path d="M1249.73-93.32c26,31.62,82.24,95.61-106.82,116.62-48.62,5.41-113.48,8-199.25,6.4C852.39,28,760.25,23.23,670.33,23.5c-73.17.22-140.35,3.8-211.94,5.05-92,1.62-189.07-.64-283.4-.59S57.4,26.85,0,32.69V0H1142.91" style="fill:#f4eeed"/></g></svg>`

const BootstrapNavbar = () => {
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 500);
        });
    }, []);

    const waveStyle = {
        backgroundImage: `url(${wave})`
    }

    return (
        <>
            <Navbar expand="lg" sticky="top" collapseOnSelect className="nav-wrap mobile">
                <div className="navWrapper">
                    <div className="brand">
                        <Navbar.Brand href="/">
                            <InlineSVG src={logo} />
                        </Navbar.Brand>
                    </div>
                    <Navbar.Toggle aria-controls="basic-navbar-nav">MENU</Navbar.Toggle>
                </div>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Scrollspy scrollTargetIds={['o-nas', 'sluzby', 'cennik', 'kontakt']} activeNavClass="active" className="navbar-nav" >
                        <Nav.Link className="nav-item" href="#o-nas"><span>O nás</span></Nav.Link>
                        <Nav.Link className="nav-item" href="#sluzby"><span>Služby</span></Nav.Link>
                        <Nav.Link className="nav-item" href="#cennik"><span>Cenník</span></Nav.Link>
                        <Nav.Link className="nav-item" href="#kontakt"><span>Kontakt</span></Nav.Link>
                    </Scrollspy>
                </Navbar.Collapse>
            </Navbar >
            <Navbar className={scroll ? "scroll nav-wrap main" : 'nav-wrap main'} style={scroll ? waveStyle : {}}>
                <div className="navWrapper">
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Scrollspy
                            items={['o-nas', 'sluzby', 'cennik', 'kontakt']}
                            currentClassName="active"
                            componentTag="div"
                            className="navbar-nav"
                            offset={30}
                        >
                            <Nav.Link className="nav-item" href="/#o-nas"><span>O nás</span></Nav.Link>
                            <Nav.Link className="nav-item" href="/#sluzby"><span>Služby</span></Nav.Link>
                            <Nav.Link className="nav-item" href="/">
                                <InlineSVG src={logo} />
                            </Nav.Link>
                            <Nav.Link className="nav-item" href="/#cennik"><span>Cenník</span></Nav.Link>
                            <Nav.Link className="nav-item" href="/#kontakt"><span>Kontakt</span></Nav.Link>
                        </Scrollspy>
                    </Navbar.Collapse>
                    <InlineSVG src={nav} />
                </div>
            </Navbar>
            <Routes>
                <Route path="*" element={<NotFoundPage />} />
                <Route exact path="/" element={<Home />} />
            </Routes>
        </>
    )
}

export default BootstrapNavbar;