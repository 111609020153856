import React, { useRef, useState, useEffect } from "react";
import picture from '../assets/img/bg3-min.jpg'
import svg from '../assets/img/green1.svg'
import InlineSVG from "svg-inline-react";
import emailjs from '@emailjs/browser';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const gps = `<svg class="icon" width="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 333.47 480.89"><g id="Layer_2" data-name="Layer 2"><g id="Ñëîé_1" data-name="Ñëîé 1"><path d="M237.67,424.91l-.08.08a2.28,2.28,0,0,0,1.32,3.95c12.28,1.2,23.81,2.72,34.27,4.52,13.95,2.41,25.43,5.21,34.12,8.32a65.41,65.41,0,0,1,8.73,3.77,1,1,0,0,1,0,1.76,63.91,63.91,0,0,1-8.73,3.78c-8.69,3.11-20.17,5.91-34.12,8.32-20.6,3.55-45.35,6-71.84,7.11-11.28.49-22.88.74-34.6.74s-23.32-.25-34.6-.74c-26.5-1.13-51.25-3.56-71.85-7.11-13.94-2.41-25.42-5.21-34.11-8.32a63.35,63.35,0,0,1-8.73-3.78,1,1,0,0,1,0-1.76,64.82,64.82,0,0,1,8.73-3.77c8.69-3.11,20.17-5.91,34.11-8.32,10.47-1.8,22-3.32,34.32-4.52A2.27,2.27,0,0,0,95.92,425a.3.3,0,0,1-.08-.08,2.35,2.35,0,0,0-1.83-.66c-54.51,5.2-92,9.37-92,22.15,0,16.22,60.39,29.66,139.28,32.07q12.45.39,25.46.39c8.65,0,17.16-.13,25.45-.39,78.9-2.41,139.28-15.85,139.28-32.07,0-12.78-37-18.87-92-22.19A2.31,2.31,0,0,0,237.67,424.91Z" style="stroke-miterlimit:10;stroke-width:4px"/><path d="M166.66,416.72,163.94,415C162.35,414,4.61,313.94,6.67,162,7.85,73.78,79.66,2,166.74,2A159.89,159.89,0,0,1,281.33,49.88a155.92,155.92,0,0,1,45.48,112.26c-2.05,160-155.84,252.05-157.39,253Zm.08-404.14C84,12.58,18.38,78.28,17.25,162.15,15.42,297.33,146.14,390.41,166.81,404.27c20.45-13.06,147.59-100,149.42-242.26A145.35,145.35,0,0,0,273.8,57.32,149.35,149.35,0,0,0,166.74,12.58Zm0,235.23a73.52,73.52,0,1,1,73.52-73.52A73.6,73.6,0,0,1,166.73,247.81Zm0-136.46a62.94,62.94,0,1,0,62.94,62.94A63,63,0,0,0,166.73,111.35Z" style="stroke-miterlimit:10;stroke-width:4px"/></g></g></svg>`
const mail = `<svg class="icon" width="28px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 436.24 304.28"><g id="Layer_2" data-name="Layer 2"><g id="Ñëîé_1" data-name="Ñëîé 1"><path d="M434.24,2H2V302.12l10.41.16,421.83-.16ZM162.89,151.14,12.41,272.74V31.25ZM423.66,12.38l-204.17,165L12.41,12.38ZM12.41,291.7l162.3-131.15,35.59,28.36,9.25,7.37,9.21-7.44,32.67-26.39L423.66,291.7Zm411.25-18.86L273.18,153,423.66,31.35Z" style="stroke-miterlimit:10;stroke-width:4px"/></g></g></svg>`
const phone = `<svg class="icon" width="28px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 397.87 361.26"><g id="Layer_2" data-name="Layer 2"><g id="Ñëîé_1" data-name="Ñëîé 1"><path d="M315.77,359.26c-16,0-34-4.57-53.72-13.72A695.4,695.4,0,0,1,23,143.36l-.24-.35C-9.72,91.21,2.58,52.6,15,38L37.42,11.42A26.62,26.62,0,0,1,74.94,8.3l52.53,44.44c8,6.78,15.37,17.36,1.24,57.41-3,8.39-1.77,17,3.18,23a445.31,445.31,0,0,0,122,103.24c6.76,3.88,15.42,3.62,23.2-.68,37.16-20.56,48.82-15.06,56.83-8.28l52.53,44.44a26.66,26.66,0,0,1,3.13,37.52l-22.45,26.53C354,351.46,336.64,359.26,315.77,359.26ZM31.62,137.2A684.72,684.72,0,0,0,266.69,336c41.17,19.07,72.31,16.77,92.37-7l22.45-26.53A16.06,16.06,0,0,0,379.63,280L327.09,235.5c-3.83-3.24-11-9.29-44.86,9.47-11.06,6.12-23.62,6.34-33.61.59A456.11,456.11,0,0,1,123.71,139.87c-7.32-8.9-9.18-21.32-5-33.23,12.88-36.53,5.74-42.57,1.91-45.82L68.11,16.37A16,16,0,0,0,45.5,18.26L23.05,44.79C13.25,56.37,2.45,90.58,31.62,137.2Z" style="stroke-miterlimit:10;stroke-width:4px"/></g></g></svg>`

const schema = yup.object().shape({
    email: yup.string().email().required(),
});

const Contact = () => {

    const [windowWidth, setWindowWidth] = useState(0);
    useEffect(() => {
        setWindowWidth(window.innerWidth);
        window.addEventListener("resize", () => {
            setWindowWidth(window.innerWidth);
        });
    }, []);

    const form = useRef();
    const { register, handleSubmit, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmitHandler = () => {

        emailjs.sendForm('service_egiq2lf', 'template_4o51x7n', form.current, 'user_FcszVnyDLkBaKjSx9jqDX')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        reset();
    };
    return (
        <div className="tpl__contact" id="kontakt" >
            <div className="tpl" style={{ WebkitMask: `url(${svg}) no-repeat 100%`, WebkitMaskSize: "cover", backgroundImage: windowWidth > 991 ? `url(${picture})` : "" }}>
                <div className="container">
                    <div className="row">
                        <div className="grid-xs-24 grid-l-12 grid-l-offset-12 bg">
                            <div className="content">
                                <div className="comp__heading">
                                    <h2 className="heading heading--beige">Kontakt</h2>
                                    <div className="delimiter delimiter--beige"></div>
                                    <div className="icon icon-red "></div>
                                </div>
                                <div className="contactInformation">
                                    <div className="name">Ing. Lenka Gogová - GOSAR</div>
                                    <div className="address">
                                        <InlineSVG src={gps} />
                                        <div className="text">
                                            <p>Jenisejská 2441/45A</p>
                                            <p>040 12 Košice</p>
                                        </div>
                                    </div>
                                    <div className="registrationNumber">
                                        <p>IČO: 54 035 368</p>
                                        <p>DIČ: 1081366990</p>
                                        <p>Nie sme platcami DPH</p>
                                    </div>
                                    <div className="contact">
                                        <div className="contactItem">
                                            <InlineSVG src={phone} />
                                            <a href="tel:+421 951 688 363">+421 951 688 363</a>
                                        </div>
                                        <div className="contactItem">
                                            <InlineSVG src={mail} />
                                            <a href="mailto:gosar@gosar.sk">gosar@gosar.sk</a>
                                        </div>
                                    </div>
                                </div>
                                <form
                                    ref={form}
                                    className="comp__contactForm"
                                    onSubmit={handleSubmit(onSubmitHandler)}
                                >
                                    <div className="formTitle">Kontaktný formulár</div>
                                    <div className="items">
                                        <div className="item item--2cols">
                                            <div className="labels">
                                                <label htmlFor="firstName">Meno</label>
                                                <input
                                                    {...register("firstName")}
                                                    type="text"
                                                    name="firstName"
                                                    id="firstName"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="item item--2cols">
                                            <div className="labels">
                                                <label htmlFor="lastName">Priezvisko</label>
                                                <input
                                                    {...register("lastName")}
                                                    type="text"
                                                    name="lastName"
                                                    id="lastName"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="items">
                                        <div className="item">
                                            <div className="labels">
                                                <label htmlFor="email">E-mail</label>
                                                <input
                                                    {...register("email")}
                                                    name="email"
                                                    id="email"
                                                    type="email"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="items">
                                        <div className="item">
                                            <div className="labels">
                                                <label htmlFor="message">Správa</label>
                                                <textarea
                                                    {...register("textarea")}
                                                    name="message"
                                                    id="message"
                                                    rows={6}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn--lightWhite">Poslať</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}


export default Contact;