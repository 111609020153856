import React from "react";
import svg from '../assets/img/pink.svg'
import marble from '../assets/img/marble.svg'

const Services = () => {
    return (
        <div className="tpl__services" id="sluzby">
            <div className="tpl">
                <div className="container-medium">
                    <div className="row wrapper">
                        <div className="grid-xs-24 grid-m-6 grid-l-7">
                            <div className="content">
                                <div className="comp__heading">
                                    <h2 className="heading heading--green">Služby</h2>
                                    <div className="delimiter delimiter--green"></div>
                                </div>
                                <div className="description">
                                    <p className="text text--green">Chcete sa zúčastniť verejného obstarávania a neviete ako nato? Nechajte prípravu ponuky na nás.</p>
                                    <p className="text text--green">Poskytneme vám komplexné služby <strong>v jednotlivých krokoch</strong>.</p>
                                </div>
                            </div>
                        </div>
                        <div className="grid-xs-24 grid-m-17 grid-m-offset-1 grid-l-16 items">
                            <div className="item">
                                <div className="bg" style={{ backgroundImage: `url(${marble})` }} />
                                <div className="number">01</div>
                                <div className="content">
                                    <h3 className="title">Monitoring</h3>
                                    <p className="text">Monitoring verejných obstarávaní podľa zvoleného profilu uchádzača</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="bg" style={{ backgroundImage: `url(${marble})` }} />
                                <div className="number">02</div>
                                <div className="content">
                                    <h3 className="title">Analýza</h3>
                                    <p className="text">Objasnenie špecifík a podmienok zvoleného verejného obstarávania</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="bg" style={{ backgroundImage: `url(${marble})` }} />
                                <div className="number">03</div>
                                <div className="content">
                                    <h3 className="title">Výber</h3>
                                    <p className="text">V prípade záujmu výber verejného obstarávania</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="bg" style={{ backgroundImage: `url(${marble})` }} />
                                <div className="number">04</div>
                                <div className="content">
                                    <h3 className="title">Ponuka</h3>
                                    <p className="text">Príprava a kompletizácia ponuky</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="bg" style={{ backgroundImage: `url(${marble})` }} />
                                <div className="number">05</div>
                                <div className="content">
                                    <h3 className="title">Poradenstvo</h3>
                                    <p className="text">Poradenstvo pri registrácií v systémoch verejného obstarávania, účasti v elektronickej aukcii</p>
                                </div>
                            </div>
                            <div className="item">
                                <div className="bg" style={{ backgroundImage: `url(${marble})` }} />
                                <div className="number">06</div>
                                <div className="content">
                                    <h3 className="title">Komunikácia</h3>
                                    <p className="text">Spolupráca pri komunikácií s verejným obstarávateľom</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="overlay" style={{ backgroundImage: `url(${svg})` }}></div>
        </div>
    )
}


export default Services;