import React from 'react';
import './less/index.less';
import BootstrapNavbar from './Nav'
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga';
ReactGA.initialize('UA-215934290-1');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends React.Component {
  render() {
    document.title = 'Gosar | Komplexná podpora a poradenstvo pri príprave ponúk v rámci verejného obstarávania';
    return (
      <div className="page-wrap">
        <BootstrapNavbar />
        <CookieConsent
          enableDeclineButton
          // debug={true}
          declineButtonClasses="btn btn--decline"
          buttonClasses="btn btn--lightWhite btn--cookie"
          disableButtonStyles="true"
          contentClasses="content"
          style={{ background: "#f4eeed" }}
          declineButtonText={"Zamietnuť"}
          buttonText={"Povoliť"}
        >
          Tento web používa na poskytovanie služieb, personalizáciu reklám a analýzu návštevnosti súbory cookies. Používaním tohto webu s tým súhlasíte.
        </CookieConsent>
        {/* <div id='nette-debug'></div> */}
      </div>
    )
  }
}

export default App