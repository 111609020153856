import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
    return (
        <div className="tpl__notFoundPage">
            <div className="tpl">
                <div className="container">
                    <div className="row">
                        <div className="grid-xs-18 grid-xs-offset-3">
                            <div className="content">
                                <h1 className="title">404</h1>
                                <p>Stránku, ktorú hľadáte sa nepodarilo nájsť.</p>
                                <Link to="/">Späť na hlavnú stránku</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NotFoundPage;