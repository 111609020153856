import React from "react";
import bg from '../assets/img/bg-min.jpg'
import overlay from '../assets/img/lightwhite1.svg'

const Header = () => {
    return (
        <div className="tpl__mainHeader" id="uvod">
            <div className="tpl" style={{ backgroundImage: `url(${bg})` }}>
                <h1 className="heading">gosar</h1>
                <div className="container-fluid">
                    <div className="row">
                        <div className="grid-xs-20 grid-xs-offset-4 grid-s-12 grid-s-offset-12">
                            <div className="content">
                                <div className="textContent">
                                    <div className="text">
                                        Komplexná podpora a poradenstvo pri príprave ponúk v rámci verejného obstarávania
                                        <div className="delimiter delimiter--lightPink"></div>
                                    </div>
                                    <a href="#kontakt" className="btn btn--lightWhite">Mám záujem</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="overlay" style={{ backgroundImage: `url(${overlay})` }}></div>
        </div>
    )
}


export default Header;