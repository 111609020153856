import React from 'react';
import Header from "./components/Header";
import './less/index.less';
import About from "./components/About";
import Footer from "./components/Footer";
import Services from "./components/Services";
import Pricelist from "./components/Pricelist";
import Contact from "./components/Contact";

const Home = () => {
    return (
        <>
            <Header />
            <About />
            <Services />
            <Pricelist />
            <Contact />
            <Footer />
        </>
    );
}

export default Home;