import React from "react";
import InlineSVG from "svg-inline-react";
const before = `<svg class="before" width="6px" height="21px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.28 89.85"><polygon points="0 16.19 25.28 0 25.28 89.85 0 74.05 0 16.19" style="fill-rule:evenodd"/></svg>`;

const Footer = () => {
    return (
        <div className="tpl__footer">
            <div className="tpl">
                <div className="container-medium">
                    <div className="row">
                        <div className="grid-xs-24">
                            <div className="content">
                                <p>2022 <InlineSVG src={before} /> created by <a href="https://patrikpalko.com/" target="_blank" rel="noreferrer">patrik palko</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Footer;